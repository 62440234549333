$blue: #0d6efd;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #d63384;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #198754;
$teal: #20c997;
$cyan: #0dcaf0;
$white: #fff;
$gray-600: #6c757d;
$gray-800: #343a40;

// $test1: #0d6efd;$test1: #2bb0ce;$test1: #3ca9c3;$test1: #47a1b8;$test1: #5099ae;$test1: #5692a3;$test1: #5b8b98;$test1: #5f838e;$test1: #627c84;$test1: #64747a;$test1: #656d70;$test1: #666666;


$colours: ($blue,
  $indigo,
  $purple,
  $pink,
  $red,
  $orange,
  $yellow,
  $green,
  $teal,
  $cyan,
  // $white,
  // $gray-600,
  // $gray-800
);

$rowHeight: 1.5em;

html, body, .App {
  min-width: 1600px;
}

// h1 {
//   margin: 1em!important;
// }

#date-filter {
  margin: 1em;

  label {
    padding: 0 1em;
  }

  input {
    display: inline-block;
    width: auto;
  }
}

span.warning {
  color: #ccbb00;
}

.filter-multi-select {
  font-size: .95em;
  padding: 0;
  border: 0;
}

input[type='number'] {
  // text-align: center;
  position: relative;
}

input[type='number']::-webkit-inner-spin-button {
  position: absolute;
  width: 12.5%;
  // height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  margin: 2px;
}

.filtered-table-container,
.tv-table {
  box-shadow: 0px 0px 5px 0px #00000063;
  border-radius: 3px;
  margin: auto;
}

.packing-table,
.packing-table-filters {
  table-layout: fixed;
  width: 100%;
  margin: auto;
  border-collapse: collapse;


  overflow: hidden;
  // position: relative;

  ::-webkit-scrollbar {
    height: 1px;
    width: 1px;
    background: transparent;
    // transform:rotateX(180deg);
    // -ms-transform:rotateX(180deg); /* IE 9 */
    // -webkit-transform:rotateX(180deg); /* Safari and Chrome */
  }

  ::-webkit-scrollbar-thumb:horizontal {
    background-color: #bbb;
    border-radius: 5px;
  }

  tr {
    height: $rowHeight;
    line-height: $rowHeight;
  }

  td,
  th {
    border: 1px solid #bbb;
    padding: 0.1rem 0.5rem;
    text-align: left;
    // position: sticky;
    // top: 0; /* Don't forget this, required for the stickiness */
  }

  tbody {
    .font-size-80 {
      font-size: 80%;
    }

    .font-size-60 {
      font-size: 60%;
    }

    .font-size-40 {
      font-size: 40%;
    }

    .font-size-20 {
      font-size: 20%;
    }

    td.good>input,
    td.good>input::placeholder {
      color: #008000;
      font-weight: bold;
    }

    td.warning>input,
    td.warning>input::placeholder {
      color: #ccbb00;
      // font-weight: bold;
    }

    .packing-date {
      td {
        font-weight: bolder;
        font-size: 1rem;
        padding: 0.5rem 1rem 0.2rem;
        border-top: 2px solid #bbb;
        border-bottom: 2px solid #bbb;
        height: 2rem;
        text-align: center;
        letter-spacing: 0.1rem;
      }
    }

    .even {
      background: #eee;
    }

    .odd {
      background: #fff;
    }

    .production-order-number,
    .item-description {
      overflow-x: auto;
      white-space: nowrap;
      // scrollbar-width: thin;
      scrollbar-width: none;
      // font-size: 0.5vw;
    }

    // colour each batch
    .production-order-number {
      span {
        border-radius: 5px;
        margin: 0 2px;
        padding: 0 5px;
        font-weight: 400;
      }

      @for $i from 1 through length($colours) {
        span:nth-child(#{length($colours)}n + #{$i}) {
          background: lighten(nth($colours, $i), 40%);
          color: darken(nth($colours, $i), 15%);
        }
      }
    }
  }

  .priority {
    width: 5%;

    input {
      text-align: center;
    }
  }

  .production-order-number {
    width: 10%;
  }

  // .item-description {

  // }

  // .product-code {
  //   width: 5%;
  // }

  .quantity {
    width: 5%;
  }

  .status {
    width: 10%;
  }

  .packing-lines {
    width: 10%;
  }

  .site {
    width: 10%;
  }

  .packing-status {
    width: 8%;
  }

  .location-code {
    width: 5%;
  }

  .show {
    width: 5%;
  }

  .product-code,
  .quantity,
  .packing-line,
  .packing-lines,
  .site,
  .packing-status,
  .location-code,
  .priority,
  .show {
    text-align: center;
  }
}

.admin-container {
  width: 96%;
  min-width: 1600px;
  margin: 1em auto;
}

.tv-container {
  width: 98%;
  margin: 1vw auto;
  overflow: hidden;
  font-size: 1.2vw;
  height: 94.3vh;

  .tv-table-header {
    display: flex;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    width: 99%;
    margin: 1vw auto;

    div {
      width: 30%;
      text-align: center;

      h1 {
        font-size: 1.6vw;
      }

      h2 {
        font-size: 1.4vw;
      }
    }

    >div:first-child {
      // width: 15%;
      text-align: left;
      // img {
      //   width: 70%;
      // }
    }

    >div:last-child {
      // width: 10%;
      img {
        width: 2.5vw;
        float: right;
      }
    }
  }

  .packing-table {
    .priority {
      width: 5%;
    }

    .production-order-number {
      width: 9%;
    }

    .quantity {
      width: 7%;
    }

    .packing-line, .packing-lines {
      width: 7%;
    }

    .site {
      width: 8%;
    }

    .packing-status {
      width: 8%;
    }
  }

  .multi-table {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    >div {
      width: 100%;
      overflow: hidden;
    }

    >div:first-child {
      align-self: flex-start;
    }

    >div:last-child {
      align-self: flex-end;
    }
  }
}